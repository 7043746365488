<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
       <b>{{ formTitle == '添加' ? $t('通用.新增') : $t('通用.修改') }} </b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('服务管理.轮播图管理.标题')" prop="title">
        <a-input v-model="form.title" :placeholder="$t('服务管理.轮播图管理.请输入标题')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务内容管理.图片')" prop="image">
        <file-upload :defaultList="imageList" type="image" :count="1" @input="imageChange"></file-upload>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.轮播图管理.位置')" prop="position" >
        <a-select v-model="form.position" :placeholder="$t('服务管理.轮播图管理.请选择位置')">
          <a-select-option :value="1">首页</a-select-option>
          <a-select-option :value="2">服务</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.轮播图管理.类型')" prop="type">
        <a-select v-model="form.type" :placeholder="$t('服务管理.轮播图管理.请选择类型')">
          <a-select-option :value="1">后巷屋详情</a-select-option>
          <a-select-option :value="2">服务者详情</a-select-option>
          <a-select-option :value="3">新闻详情</a-select-option>
          <a-select-option :value="4">公告详情</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.轮播图管理.跳转地址')" prop="url">
        <a-input v-model="form.url" :placeholder="$t('服务管理.轮播图管理.请输入跳转地址')" />
      </a-form-model-item>
      <!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> {{$t('通用.保存')}} </a-button>
          <a-button type="dashed" @click="cancel"> {{$t('通用.取消')}} </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBanner, addBanner, updateBanner } from '@/api/banner/banner'
import { guid } from '@/utils/ruoyi'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      imageList: [],
      // 表单参数
      form: {
        id: null,

        title: null,

        image: null,

        position: null,

        type: null,

        url: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        image: [{ required: true, message: '图片不能为空', trigger: 'blur' }],
        position: [{ required: true, message: '位置,1-首页,2-服务不能为空', trigger: 'blur' }],
        type: [
          {
            required: true,
            message: '类型,1-后巷屋详情,2-服务者详情,3-新闻详情,4-公告详情不能为空',
            trigger: 'change',
          },
        ],
        /*url: [
          { required: true, message: '跳转地址不能为空', trigger: 'blur' }
        ]*/
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1

      this.$nextTick(() => {
        this.form = {
          id: null,
          title: null,
          image: null,
          position: null,
          type: null,
          url: null,
          createTime: null,
          remark: null,
        }
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
      this.imageList = []
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      this.imageList = []
      this.imageList.push({
        uid: guid(8, 10),
        name: row.image,
        status: 'done',
        halfUrl: row.image,
        url: row.image,
        path: row.image,
      })
    },
    /** 提交按钮 */
    submitForm: function () {

      this.$refs.form.validate((valid) => {
        if (valid) {
          // this.form.image = this.form.image[0].path
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addBanner(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    imageChange(file){
        //  console.log('info',file)
         if(file.length == 0){
             this.form.image = ''
             this.imageList = []
         }else{
             this.form.image= file[0].path
         }
    }
  },
}
</script>
